import React from 'react';
import moment from 'moment';
import 'antd/dist/antd.css';
import {Spin, Layout, PageHeader, Input, Select, DatePicker, Descriptions, Button} from 'antd';
import { Session } from './'
import { gql } from "apollo-boost";

const { Content } = Layout;
const { Option } = Select;

const FETCH_SESSION_URNS = gql`
  query Fetch($tags: [String]){ 
    urnsByTags(
      tags: $tags, 
      urnPrefix: "urn:cloud:qa:ses:"
    ) 
  }
`;

class Sessions extends React.Component {
 constructor(props) {
  super(props);
  this.state = {
    sessions: {},
    complete: {},
    completeInView: 0,
    lastComplete: null,
    latestViewMode: true,
    filterValues: {
      username: new Set(),
      deviceId: new Set()
    },
    filterBy: {
      username: "",
      deviceId: "",
      successful: "",
    },
    search: {
      complete: moment().format("YYYY-MM-DD")
    },
    searching: false
  };
  this.state.query = this.createQueryTags(this.state.search)
}

updateSession(update) {
  let newState = Object.assign({}, this.state);
  if (!('timestamp' in update)) {
      update.timestamp = 0; 
  }
  
  if(!(update.id in newState.sessions) || update.timestamp > newState.sessions[update.id].timestamp) {
      newState.sessions[update.id] = {...newState.sessions[update.id], ...update }
  } 

  this.setState(newState);
}

updateCompleteSession(id) {
  let newState = Object.assign({}, this.state);
  delete newState.complete[id]
  this.setState(newState);
}

onDetailOpenClose(index, keys) {
  let newState = Object.assign({}, this.state);
  newState.sessions[index].openDetails = keys.includes(index)
  this.setState(newState);
}

pingWebSocket() {
  if (this.props.wsClient.readyState === this.props.wsClient.OPEN) {
    this.props.wsClient.send(JSON.stringify({"route":"subscribe","authorization":this.props.token}));
  }
}

componentWillUnmount() {
  clearInterval(this.state.intervalId);
}

componentDidMount() {
  this.props.wsClient.onopen = () => {
    console.log('WebSocket Client Connected');
    this.pingWebSocket();
    var intervalId = setInterval(this.pingWebSocket.bind(this), 60000);
    this.setState({intervalId: intervalId});
  };
  this.props.wsClient.onmessage = (message) => {
    if(message.data === "success") {
      console.log("WebSocket Client Subscribed");
    } else {
      this.updateSession(JSON.parse(message.data));
    }
  };

  fetch(this.props.qaCore+"/session/all/active", {
    "method": "GET",
    "headers": {
      "authorization": this.props.token
    }
  })
  .then((response) => response.json())
  .then((sessions) => {
     sessions.forEach(this.updateSession.bind(this))
  })
  .catch(err => {
    console.log(err);
  });
 
  this.fetchMostRecentCompleteSessions();
}

findDeviceId(session) {
   for(const step of session["steps"]) {
     if("deviceId" in step["params"]) {
       return step["params"]["deviceId"];
     }
   }
   return null;
}

fetchMostRecentCompleteSessions() {
  let newState = Object.assign({}, this.state);
  newState.searching = true;
  this.setState(newState);

  let url = this.props.qaCore+"/session/page/complete/10"
  if(this.state.lastComplete != null) {
    url = url + "/from/" + this.state.lastComplete.id + '_' + this.state.lastComplete.completeTime;
  }
  fetch(url, {
    "method": "GET",
    "headers": {
      "authorization": this.props.token
    }
  })
    .then((response) => response.json())
    .then((sessions) => {
      let newState = Object.assign({}, this.state);
      newState.searching = false;
      for(const session of sessions) {
        session["isInView"] = this.isInView(session);
        if(session["isInView"]) {
          newState.completeInView++;
        }
        const deviceId = this.findDeviceId(session)
        if(deviceId) {
          newState.filterValues.deviceId.add(deviceId);
          session["deviceId"] = deviceId;
        }
        newState.complete[session.id] = session;
        newState.lastComplete = session;
        newState.filterValues.username.add(session.username);
      }
      this.setState(newState);
    })
    .catch(err => {
      console.log(err);
    })
}

isInView(session) {
  if(this.state.filterBy.username && session.username !== this.state.filterBy.username) {
   return false
  }
  if(this.state.filterBy.deviceId && session.deviceId !== this.state.filterBy.deviceId) {
    return false
  }
  if(this.state.filterBy.successful && session.successful.toString() !== this.state.filterBy.successful) {
    return false
  }
  return true
}

fetchSession(urn) {
  let id = urn.slice(17) //remove urn prefix urn:cloud:qa:ses:
  fetch(this.props.qaCore+"/session/"+id, {
    "method": "GET",
    "headers": {
      "authorization": this.props.token
    }
  })
  .then((response) => response.json())
  .then((session) => {
    if(!this.state.latestViewMode) {
      let newState = Object.assign({}, this.state);
      newState.searching = false;
      session.isInView = true;
      newState.complete[session.id] = session;
      this.setState(newState);
    }
  })
  .catch(err => {
    console.log(err);
  })
}

fetchSessionUrns() {
  let newState = Object.assign({}, this.state);
  newState.complete = [];
  newState.searching = true;
  this.setState(newState);

  this.props.graphQl.query({
    query: FETCH_SESSION_URNS,
    variables: { tags: this.state.query }
  })
  .then((response) => response.data.urnsByTags)
  .then((urns) => {
    if(urns.length < 1) {
      let newState = Object.assign({}, this.state);
      newState.searching = false;
      this.setState(newState)
    } else {
      urns.forEach(this.fetchSession.bind(this));
    }
  })
  .catch(err => {
    let newState = Object.assign({}, this.state);
    newState.searching = false;
    this.setState(newState)
    console.log(err)
  });
}

createQueryTags(search) {
  let query = []
  for (let [key, value] of Object.entries(search)) {
    if(value) {
      query.push(`${key}:${value}`)
    }
  }
  return query
}

updateSearchState(field, value) {
  let newState = Object.assign({}, this.state);
  newState.search[field] = value
  newState.query = this.createQueryTags(newState.search)
  // console.log(newState.query);
  this.setState(newState);
}

updateSearchDate(_, date) {
  // console.log(date)
  this.updateSearchState("complete", date)
}

updateSearchUrn(e) {
  // console.log(e.target.value)
  this.updateSearchState("channel", e.target.value)
}

updateSearchId(e) {
  // console.log(e.target.value)
  this.updateSearchState("deviceId", e.target.value)
}

updateSearchSuccess(value) {
  // console.log(value)
  this.updateSearchState("successful", value)
}

updateFilterBy(field, value) {
  let newState = Object.assign({}, this.state);
  newState.filterBy[field] = value;
  this.setState(newState);
}

resetFilters() {
  let newState = Object.assign({}, this.state);
  Object.keys(newState.filterBy).forEach((k) => newState.filterBy[k] = "");
  this.setState(newState, () => this.applyFilters());
}

applyFilters() {
  let newState = Object.assign({}, this.state);
  newState.completeInView = 0;
  for(const k of Object.keys(newState.complete)) {
    newState.complete[k].isInView = this.isInView(newState.complete[k]);
    if(newState.complete[k].isInView) {
      newState.completeInView++;
    }
  }
  this.setState(newState);
}

switchViewMode() {
  let newState = Object.assign({}, this.state);
  newState.latestViewMode = !this.state.latestViewMode;
  newState.complete = {};
  newState.search = {
    complete: moment().format("YYYY-MM-DD")
  };
  newState.query = this.createQueryTags(newState.search)
  Object.keys(newState.filterBy).forEach((k) => newState.filterBy[k] = "");
  newState.completeInView = 0;
  newState.lastComplete = null;
  this.setState(newState, () => {
    if(this.state.latestViewMode) {
      this.fetchMostRecentCompleteSessions();
    } else {
      this.fetchSessionUrns();
    }
  });
}

render() {
  return (<div>
    <div className='sessions'>
      <Layout className="sessions" style={{background: '#fff'}}>
        <PageHeader
            className='header'
            title="QA Core Monitor"
            extra={<Button onClick={() => this.props.logout({returnTo: window.location.origin})}>Log Out</Button>}
        />
        <Content className='content'>
          {
            Object.keys(this.state.sessions).map((id, index) => {
              let session = this.state.sessions[id]
              return (
                  <Session
                      key={index}
                      id={id}
                      value={session}
                      onDetailOpenClose={this.onDetailOpenClose.bind(this, id)}
                      qaCore={this.props.qaCore}
                      graphQl={this.props.graphQl}
                      token={this.props.token}
                  />
              )
            })
          }
        </Content>
      </Layout>
    </div>
    <br/><br/><br/><br/>
    <div className='sessions'>
      <Layout className="sessions" style={{background: '#fff'}}>
        <PageHeader
          className='header'
          title="Previous Sessions"
          subTitle={this.state.latestViewMode ? "Viewing most recent sessions" : "Search Mode" }
          extra={<Button onClick={this.switchViewMode.bind(this)}>{this.state.latestViewMode ? "Switch to Search Mode" : "View Most Recent Sessions" } </Button>}
        />

        {this.state.latestViewMode &&
        <Content className='content'>
          <h3>Show Only:</h3>
          <Descriptions column={2}>
            <Descriptions.Item label="Initiated by User">
              <Select value={this.state.filterBy.username} style={{width: 200}} onChange={this.updateFilterBy.bind(this, "username")} >
                <Option value="">Any</Option>
                {Array.from(this.state.filterValues.username).map((username, index) => {
                  return (
                      <Option key={index} value={username}>{username}</Option>
                  )
                })}
              </Select>
            </Descriptions.Item>
            <Descriptions.Item label="Device Id">
              <Select value={this.state.filterBy.deviceId} style={{width: 200}} onChange={this.updateFilterBy.bind(this, "deviceId")}>
                <Option value="">Any</Option>
                {Array.from(this.state.filterValues.deviceId).map((username, index) => {
                  return (
                      <Option key={index} value={username}>{username}</Option>
                  )
                })}
            </Select>
            </Descriptions.Item>
            <Descriptions.Item label="Final State">
              <Select value={this.state.filterBy.successful} style={{width: 200}} onChange={this.updateFilterBy.bind(this, "successful")}>
                <Option value="">Any</Option>
                <Option value="true">Complete</Option>
                <Option value="false">Failed</Option>
              </Select>
            </Descriptions.Item>
          </Descriptions>
          <Button type="primary" onClick={this.applyFilters.bind(this)} >Apply Filters</Button>
          <Button style={{"marginLeft":"20px"}} onClick={this.resetFilters.bind(this)} >Reset All</Button>
          <span style={{"marginLeft":"20px"}}>Currently viewing {this.state.completeInView} of {Object.keys(this.state.complete).length}</span>
        </Content>
        }

        {!this.state.latestViewMode &&
        <Content className='content'>
          <Descriptions column={2}>
            <Descriptions.Item label="Complete On"><DatePicker defaultValue={moment()}
                                                               onChange={this.updateSearchDate.bind(this)}/></Descriptions.Item>
            <Descriptions.Item label="Device ID"><Input placeholder="0000" allowClear
                                                        onChange={this.updateSearchId.bind(this)}/></Descriptions.Item>
            <Descriptions.Item label="Device URN"><Input placeholder="urn:andium:node..." allowClear
                                                         onChange={this.updateSearchUrn.bind(this)}/></Descriptions.Item>
            <Descriptions.Item label="Final State">
              <Select defaultValue="" style={{width: 200}} onChange={this.updateSearchSuccess.bind(this)}>
                <Option value="">Any</Option>
                <Option value="true">Complete</Option>
                <Option value="false">Failed</Option>
              </Select>
            </Descriptions.Item>
          </Descriptions>
          <Button type="primary" onClick={this.fetchSessionUrns.bind(this)}>Search</Button>
        </Content>
        }


        <Content className='content'>
          <Spin size="large" spinning={this.state.searching}/>
          {
            Object.keys(this.state.complete).length < 1 && <p>None found...</p>
          }
          {
            Object.keys(this.state.complete).filter(s => this.state.complete[s].isInView).sort((a, b) => this.state.complete[b].completeTime - this.state.complete[a].completeTime).map((id, index) => {
              const session = this.state.complete[id]
              return (
                  <Session
                      key={id}
                      id={id}
                      value={session}
                      deleteComplete={this.updateCompleteSession.bind(this, id)}
                      qaCore={this.props.qaCore}
                      graphQl={this.props.graphQl}
                      token={this.props.token}
                  />
              )
            })
          }
        </Content>

        {this.state.latestViewMode &&
          <Content className='content'>
            <Button type={"primary"} onClick={this.fetchMostRecentCompleteSessions.bind(this)}>Load More...</Button>
            <span style={{"marginLeft":"20px"}}>Currently viewing {this.state.completeInView} of {Object.keys(this.state.complete).length}</span>
          </Content>
        }
      </Layout>
    </div>
  </div>);
}}


export default Sessions