import React from 'react';
import 'antd/dist/antd.css';
import { Select, Button, Modal } from 'antd';

const { Option } = Select;
const { confirm } = Modal;

class RetryButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      retryStep: 0
    };
  }

  handleOk() {
    fetch(this.props.qaCore+"/session/"+this.props.value.id+"/restart/"+this.state.retryStep, {
     "method": "POST",
     "headers": {
        "authorization": this.props.token
      }
    }).then(() => {
      if(this.props.deleteComplete) this.props.deleteComplete()
    })
  }

  handleChange(value) {
    let newState = Object.assign({}, this.state);
    newState.retryStep = value;
    this.setState(newState)
  }

  showSelectModal() {
    let availableSteps = this.props.value.steps.filter(step => step.state==="COMPLETE" || step.state==="FAILED");
    
    if(availableSteps.length < 1) {
      confirm({
        title: 'Which step would you like to retry from?',
        content: 'No complete/failed steps available to retry from',
      });
    } else {
      confirm({
        title: 'Which step would you like to retry from?',
        content: 
          <Select style={{ width: 120 }} defaultValue={this.state.retryStep} onChange={this.handleChange.bind(this)} >
            {availableSteps.map((step, index) => {
              return (
                <Option key={index} value={index}>{step.name}</Option>
              )
            })}  
          </Select>,
        onOk: this.handleOk.bind(this)
      });
    }
  }

  render() {
    return(<Button onClick={this.showSelectModal.bind(this)}>Retry From Step...</Button>)
  }
}

export default RetryButton