import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import ApolloClient from 'apollo-boost';
import { Auth0LoginForm } from './components/LoginForm'
import { Sessions } from './components/Sessions'
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import 'antd/dist/antd.css';
import './index.css';

const QA_CORE_API = process.env.REACT_APP_QA_CORE_API

function App() {
  const { logout, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const getUserToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken("Bearer "+accessToken);
      } catch (e) {
        console.log(e.message);
      }
    };

    getUserToken();
  }, [getAccessTokenSilently]);

  if(isLoading || (isAuthenticated && !token)) {
    return <div>Loading ...</div>;
  }

  if(!isAuthenticated) {
    return( 
      <Auth0LoginForm />
    )
  } else {
    const GRAPHQL = new ApolloClient({
      uri: process.env.REACT_APP_GRAPHQL,
      headers: {
        authorization: token,
      }
    });
    const WS_CLIENT = new W3CWebSocket(process.env.REACT_APP_WS_CLIENT, null, window.location.origin, {'X-test':'test'}, null);
    return( 
      <div>
        <Sessions 
          wsClient={ WS_CLIENT }
          graphQl={ GRAPHQL }
          qaCore={ QA_CORE_API } 
          token={ token } 
          logout={ logout }
        />
      </div>
    )
  }
}


// ========================================

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT}
    redirectUri={window.location.href}
    audience={process.env.REACT_APP_AUTH0_AUD}
    scope={process.env.REACT_APP_AUTH0_SCOPE}
  >
    <App />
  </Auth0Provider>, 
  document.getElementById("root")
);

