import React from 'react';
import 'antd/dist/antd.css';
import { Layout, PageHeader, Button, Result, Icon } from 'antd';
import { useAuth0 } from "@auth0/auth0-react";

const { Content } = Layout;

function Auth0LoginForm() {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className='images'>
    <Layout className="images" style={{ background: '#fff' }}>
      <PageHeader
        className='header'
        title="QA Core"
      >
      </PageHeader>
    </Layout>
    <Content className='content'>
        <div className='controls'>
          <Result
            icon={<Icon 
              type="unlock"
              theme="twoTone" 
              twoToneColor="#05BBBB"/>
            }
            title="Please click to log in"
            extra={
              <Button type="primary" onClick={() => loginWithRedirect()}>Log In</Button>
            }
          />
        </div>
      </Content>
    </div>
  )

}

export default Auth0LoginForm;
