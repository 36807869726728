import React from 'react';
import 'antd/dist/antd.css';
import { Layout, PageHeader, Button, Result, Icon, Input, Form} from 'antd';

const { Content } = Layout;

class Login extends React.Component {
  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values.apikey)
      }
    });
  }

  render() {
    const { getFieldDecorator, getFieldError, isFieldTouched } = this.props.form;
    // Only show error after a field is touched.
    const apikeyError = isFieldTouched('apikey') && getFieldError('apikey');
    return (
      <div className='images'>
      <Layout className="images" style={{ background: '#fff' }}>
        <PageHeader
          className='header'
          title="QA Image Check"
        >
        </PageHeader>
      </Layout>
      <Content className='content'>
          <div className='controls'>
            <Result
              icon={<Icon 
                type={this.props.notAuthorized ? "frown" : "unlock"} 
                theme="twoTone" 
                twoToneColor={this.props.notAuthorized ? "#EE2354" : "#05BBBB"}/>
              }
              title="Please enter your API key"
              subTitle={this.props.notAuthorized ? "Not Authorized" : null}
              extra={
                <Form onSubmit={this.handleSubmit.bind(this)}>
                <Form.Item validateStatus={apikeyError ? 'error' : ''} help={apikeyError || ''}>
                  {getFieldDecorator('apikey', {
                    rules: [{ required: true, message: 'Please input your API Key' }]
                  })(
                    <Input placeholder="API key..." />
                  )}
                </Form.Item>
                  <Form.Item>
                      <Button htmlType="submit" type="primary" >Submit</Button>
                  </Form.Item>
                </Form>
              }
            />
          </div>
        </Content>
      </div>
    )
  }
}

const LoginForm = Form.create({ name: 'login'})(Login);
export default LoginForm;
