import React from 'react';
import moment from 'moment';
import 'antd/dist/antd.css';
import { Timeline, Descriptions, Layout, Collapse, Icon} from 'antd';
import { RetryButton } from './'
import { Logs } from '../Logs'

const { Content } = Layout;
const { Panel } = Collapse;

function formatDateTime(value) {
  return moment.unix(value).format("YYYY-MM-DD HH:mm:SS")
}

class Session extends React.Component {
  stepIcon(state, size='18px', addText=false) {
    if(state === "COMPLETE") {
      return <span><Icon type="check-circle" style={{ fontSize: size, color: '#79d279'}}/>{ (addText) ? " Complete" : null}</span>
    } else if(state === "FAILED") {
      return <span><Icon type="close-circle" style={{ fontSize: size, color: '#ff1a1a'}}/>{ (addText) ? " Failed" : null}</span>
    } else if(state === "IN_PROGRESS") {
      return <span><Icon type="loading" style={{ fontSize: size, color: '#008ae6'}}/>{ (addText) ? " In Progress" : null}</span>
    } 

    return <span><Icon type="clock-circle" style={{ fontSize: size, color: '#c2c2d6'}}/>{ (addText) ? " Waiting" : null}</span>
  }

  getStepItem(step, isComplete) {
    return (
      <div>
      { step.name + " " }  
      {(step.params._metaData && step.params._metaData.progressUrl) && <a 
              className='stepLink' 
              href={step.params._metaData.progressUrl} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              (Click Here)
            </a>}
      {isComplete && false }
      </div>
    )
  }

  render() {
    let isComplete = (this.props.value.completeTime!==-1)
    let current = this.props.value.steps.findIndex(s => s.state!=="COMPLETE")

    if(current === -1) {
      current = this.props.value.steps.length-1
    }

    return (
      <div className='steps'>
        <Collapse onChange={this.props.onDetailOpenClose}>
          <Panel key={this.props.value.id} header={
            <Descriptions column={3}>
              <Descriptions.Item label="Name">{this.props.value.name}</Descriptions.Item>
              <Descriptions.Item label="Start Time">{formatDateTime(this.props.value.startTime / 1000)}</Descriptions.Item>
              <Descriptions.Item label="Initiated By">{this.props.value.username}</Descriptions.Item>
              <Descriptions.Item label="Current Step">{this.props.value.steps[current].name +" ("+(current + 1) +"/"+ this.props.value.steps.length+")"}</Descriptions.Item>
              { isComplete && <Descriptions.Item label="End Time">{formatDateTime(this.props.value.completeTime)}</Descriptions.Item> }
              <Descriptions.Item label="State">{this.stepIcon(this.props.value.steps[current].state, '28px', true)}</Descriptions.Item>
            </Descriptions>
          }>
          <Descriptions column={2}>
            <Descriptions.Item label="Session ID">{this.props.value.id}</Descriptions.Item>
            { isComplete && <Descriptions.Item><RetryButton value={this.props.value} qaCore={this.props.qaCore} token={this.props.token} deleteComplete={this.props.deleteComplete} /></Descriptions.Item> }
            
          </Descriptions>
          <Content style={{padding: '16px', backgroundColor: '#fafafa', paddingBottom: '0'}}>
          <h3>Steps</h3>
          <Timeline style={{marginTop: '20px'}}>        
            {this.props.value.steps.map((step, index) => {
              return (
                <Timeline.Item key={index} dot={this.stepIcon(step.state)} style={{fontSize: '1.1em'}}>{this.getStepItem(step)}</Timeline.Item>
              )
            })}    
            </Timeline>
          </Content>

          <Logs graphQl={this.props.graphQl} qaCore={this.props.qaCore} session={this.props.value.id} allowRefresh={true} isLive={this.props.value.openDetails && !isComplete} />
          </Panel>
        </Collapse>    
     </div>   
     
     )
  }
}

export default Session