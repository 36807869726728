import React from 'react';
import moment from 'moment';
import 'antd/dist/antd.css';
import { Layout, Collapse, Button, Icon} from 'antd';
import { gql } from "apollo-boost";

const { Content } = Layout;
const { Panel } = Collapse;

const FETCH_LOGS = gql`
  query Fetch($urns: [ID]!, $startTime: Int!, $endTime: Int!){ 
    dataByUrns(
      urns: $urns, 
      startTime: $startTime, 
      endTime: $endTime
    ) 
  }
`;

function formatDateTime(value) {
  return moment.unix(value).format("YYYY-MM-DD HH:mm:SS")
}

class Logs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logsMain: [],
      logsLast: [],
      getInFlight: false
    };
  }

  updateSession(update) {
    let allLogs = this.state.logsMain.concat(this.state.logsLast).concat(update).sort((a, b) => (a.timestamp > b.timestamp) ? 1 : -1);
    this.setState({
      logsMain: allLogs.slice(0, allLogs.length-4),
      logsLast: allLogs.slice(-4),
      getInFlight: this.state.getInFlight
    });
  }

  getLogs(restartTimer = true) {
    let newState = Object.assign({}, this.state);
    newState.getInFlight = true;
    this.setState(newState)

    let now = Math.floor(Date.now() / 1000)
    let since = now - 86400
    if(this.state.logsLast.length > 0) {
      since = this.state.logsLast[this.state.logsLast.length-1].timestamp
    } 

    // fetch(this.props.qacore+"/session/"+this.props.session+"/logs"+since, {
    //  "method": "GET",
    //  "headers": {}
    // })
    this.props.graphQl.query({
        query: FETCH_LOGS,
        variables: {
          urns: ["urn:cloud:qa:dc:"+this.props.session],
          startTime: since, 
          endTime: now
      }
    })
    .then((response) => response.data)
    .then((data) => {
      if(data.dataByUrns.length > 0) {
        this.updateSession(data.dataByUrns[0].data)
      }
    })
    .then(() => {
      if(restartTimer && this.props.isLive) {
        this.timer = setTimeout(()=> this.getLogs(), 5000);
      } else {
        let newState = Object.assign({}, this.state);
        newState.getInFlight = false;
        this.setState(newState)
      }
    })
    .catch(err => {
      let newState = Object.assign({}, this.state);
      newState.getInFlight = false;
      this.setState(newState)
      console.log(err);
    });
  }

  componentDidMount() {
    this.getLogs();
  }

  componentDidUpdate(prevProps, prevState) {
    if(!prevProps.isLive && this.props.isLive) {
      this.getLogs();
    }
  }

 render() {
  let logColors = {
    background: '#333333',
    color: '#33ff33',
    fontFamily: 'Monospace',
    padding: '16px'
  }

  return (
    <div className='logs'>
    <Content style={{padding: '16px', backgroundColor: '#fafafa', paddingTop: '0'}}>
      <h3>Logs { this.props.allowRefresh &&
          <Button size="small" icon='sync' onClick={this.getLogs.bind(this, false)} />
        }
      </h3>
      <Collapse expandIconPosition={'right'} style={{border:false}}>
        <Panel className="testLogsStyle" key='1' header={ 'click to show full history ...'} >
          {this.state.logsMain.map((log, index) => {
            return (
              <p key={index}><span style={{ color: '#a6a6a6' }}>{formatDateTime(log.timestamp)+": "}</span>{log.message}</p>
            )
          })}    
        </Panel>

      </Collapse>
      <Content style={logColors}>
       {this.state.logsLast.map((log, index) => {
        return (
          <p key={index}><span style={{ color: '#a6a6a6' }}>{formatDateTime(log.timestamp)+": "}</span>{log.message}</p>
        )})}     
        { (this.props.isLive || this.state.getInFlight) && <Icon style={{ fontSize: '2em' }} type="sync" spin /> }
        
      </Content>      
    </Content> 
   </div>   
   
   )
  }
}

export default Logs;